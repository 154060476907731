// import ReactCountryFlag from 'react-country-flag'
// import {
//   UncontrolledDropdown,
//   DropdownMenu,
//   DropdownItem,
//   DropdownToggle,
// } from 'reactstrap'

// const CountryFlag = () => {
//   // ** Varss
//   const langObj = {
//     en: 'En',
//     tr: 'Tr',
//   }

//   // ** Function to switch Language
//   const handleLangUpdate = (e, lang) => {
//     e.preventDefault()
//     localStorage.setItem('selectedLang', JSON.stringify({ selectedLang: lang }))
//     window.location.reload()
//   }

//   return (
//     <UncontrolledDropdown
//       href="/"
//       tag="li"
//       className="dropdown-language nav-item"
//       id="dropdown-language"
//     >
//       <DropdownToggle
//         href="/"
//         tag="a"
//         className="nav-link"
//         id="dropdown-language-a"
//         onClick={(e) => e.preventDefault()}
//       >
//         <ReactCountryFlag
//           className="country-flag flag-icon mr-1"
//           countryCode={
//             JSON.parse(localStorage.getItem('selectedLang')).selectedLang ===
//               'en'
//               ? 'us'
//               : 'tr'
//           }
//           svg
//         />
//         <span className="selected-language">
//           {JSON.parse(localStorage.getItem('selectedLang')).selectedLang ===
//             'en'
//             ? 'English'
//             : 'Türkçe'
//           }
//         </span>
//       </DropdownToggle>
//       <DropdownMenu className="mt-0" left>
//         <DropdownItem
//           href="/"
//           tag="a"
//           onClick={(e) => handleLangUpdate(e, 'en')}
//         >
//           <ReactCountryFlag className="country-flag" countryCode="us" svg />
//           <span className="ml-1">English</span>
//         </DropdownItem>
//         <DropdownItem
//           href="/"
//           tag="a"
//           onClick={(e) => handleLangUpdate(e, 'tr')}
//         >
//           <ReactCountryFlag className="country-flag" countryCode="tr" svg />
//           <span className="ml-1">Türkçe</span>
//         </DropdownItem>
//       </DropdownMenu>
//     </UncontrolledDropdown>
//   )
// }

// export default CountryFlag

import ReactCountryFlag from 'react-country-flag'
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'

const CountryFlag = () => {
  const langObj = {
    en: 'English',
    tr: 'Türkçe',
    cn: '中文', // Chinese
    de: 'Deutsch', // German
    es: 'Español', // Spanish
    fr: 'Français', // French
    pt: 'Português', // Portuguese
    ru: 'Русский', // Russian
    sa: 'العربية', // Saudi Arabian
  }

  const handleLangUpdate = (e, lang) => {
    e.preventDefault()
    localStorage.setItem('selectedLang', JSON.stringify({ selectedLang: lang }))
    window.location.reload()
  }

  const selectedLang = JSON.parse(
    localStorage.getItem('selectedLang')
  ).selectedLang

  return (
    <UncontrolledDropdown
      href="/"
      tag="li"
      className="dropdown-language nav-item"
      id="dropdown-language"
    >
      <DropdownToggle
        href="/"
        tag="a"
        className="nav-link"
        id="dropdown-language-a"
        onClick={(e) => e.preventDefault()}
      >
        <ReactCountryFlag
          className="country-flag flag-icon mr-1"
          countryCode={
            selectedLang === 'en'
              ? 'us'
              : selectedLang === 'tr'
              ? 'tr'
              : selectedLang
          }
          svg
        />
        <span className="selected-language">
          {/* {langObj[selectedLang]} */}
        </span>
      </DropdownToggle>
      <DropdownMenu className="mt-0" left>
        {Object.keys(langObj).map((langCode) => (
          <DropdownItem
            key={langCode}
            href="/"
            tag="a"
            onClick={(e) => handleLangUpdate(e, langCode)}
          >
            <ReactCountryFlag
              className="country-flag"
              countryCode={langCode === 'en' ? 'us' : langCode}
              svg
            />
            <span className="ml-1">{langObj[langCode]}</span>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export default CountryFlag
