import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as analysisDataActions from '../redux/actions/analysisDataActions'
import { useIntl } from 'react-intl'
import { analysis } from 'redux/ApiCalls'
import MyLoader from 'components/MyLoader'
import Spinner from 'components/Spinner'
import ReactTooltip from 'react-tooltip'

const FinancialStatements = ({ isLoading, setIsLoading }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { token } = useSelector((state) => state.auth)
  const tourState = useSelector((state) => state.joyRide)

  const [lastKeyPress, setLastKeyPress] = useState(null)

  const {
    fx_position,
    maturity_short_term,
    business_plan,
    initial_custom_scenario,
    analysisResult,
    firmBackground,
    financial_statements,
  } = useSelector((state) => state.analysisDataReducer)

  let { flowOrstock } = firmBackground

  // const handleFlowChange = () => {
  //   dispatch(
  //     analysisDataActions.firmBackgroundSelect({
  //       ...firmBackground,
  //       flowOrstock: true,
  //     })
  //   )
  // }

  // const handleStockChange = () => {
  //   dispatch(
  //     analysisDataActions.firmBackgroundSelect({
  //       ...firmBackground,
  //       flowOrstock: false,
  //     })
  //   )
  // }

  const handleInputChange = (value, key, index) => {
    const newFinancialStatements = [...financial_statements]
    const findValue = newFinancialStatements[index].values.find(
      (item) => item.key === key
    )

    findValue.value = value

    dispatch(analysisDataActions.financialStatements(newFinancialStatements))

    setLastKeyPress(newFinancialStatements)
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => calculateAnalysis(), 1500)
    return () => clearTimeout(timeoutId)
  }, [financial_statements, lastKeyPress])

  const trueOrFalse = (() => {
    if (firmBackground.industry === 'Finance & Insurance') {
      return false
    }
    return true
  })()

  const calculateAnalysis = () => {
    setIsLoading(true)
    const postData = {
      firm_background: firmBackground,

      analysis_data: {
        financial: {},
        fx: {},
        maturity: {},
        business_plan: {},
        scenario: {},
      },
      is_analysis_real: trueOrFalse,
      enable_custom_scenario: analysisResult.enable_custom_scenario,
    }

    financial_statements.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.financial[value.key].push(newValue)
        })
      }
    })

    fx_position.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.fx[value.key].push(newValue)
        })
      }
    })

    maturity_short_term.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.maturity[value.key].push(newValue)
        })
      }
    })

    business_plan.map((item, index) => {
      if (index === 0) {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key] = [newValue]
        })
      } else {
        item.values.map((value) => {
          let newValue =
            typeof value.value === 'string'
              ? Number(value.value.replace(/,/g, ''))
              : value.value
          postData.analysis_data.business_plan[value.key].push(newValue)
        })
      }
    })

    if (analysisResult?.scenarios) {
      analysisResult.scenarios.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    } else {
      initial_custom_scenario.map((item, index) => {
        if (index === 0) {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key] = [newValue]
          })
        } else {
          item.values.map((value) => {
            let newValue =
              typeof value.value === 'string'
                ? Number(value.value.replace(/,/g, ''))
                : value.value
            postData.analysis_data.scenario[value.key].push(newValue)
          })
        }
      })
    }

    analysis(token, postData)
      .then(({ data }) => {
        console.log("data",data)
        dispatch(analysisDataActions.fetchRealAnalysis(data))
        setIsLoading(false)
      })
      .catch((err) => err)
  }

  const getDateDiff = () => {
    const timeDiff =
      firmBackground.foreCastEndDate - firmBackground.foreCastStartDate
    const days = timeDiff / (1000 * 60 * 60 * 24)

    const prejectionDays =
      firmBackground.projection_frequency === 'A'
        ? 365
        : firmBackground.projection_frequency === 'Q'
        ? 90
        : firmBackground.projection_frequency === 'M'
        ? 30
        : firmBackground.projection_frequency === '6M'
        ? 180
        : 1

    const result = Math.floor(days / prejectionDays)

    return new Array(result).fill(0)
  }

  const table = (
    <div className="financial-main">
      <table className="financial-main-table">
        <thead>
          <tr className="financial-main-table-tr">
            <th className="fixed-row">
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>

            <th
              className="common-row1-financial"
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
              {financial_statements.map((item, index) => (
                <th key={index} scope="col">
                  {new Date(item.year).toLocaleString('en-US', {
                    month: 'short',
                  }) +
                    ' ' +
                    new Date(item.year).toLocaleString('en-US', {
                      year: 'numeric',
                    })}
                </th>
              ))}
            </th>
            <th className="common-row3-financial">
              <th style={{ display: 'flex', justifyContent: 'space-around' }}>
                {flowOrstock === true
                  ? analysisResult?.financial_statements?.map(
                      (yearData, columnIndex) => {
                        yearData.year.split('-')
                        const date = new Date(
                          Number(yearData.year.split('-')[0]),
                          Number(yearData.year.split('-')[1]),
                          0
                        )
                        return (
                          <th scope="col" key={columnIndex}>
                            {date.toLocaleString('en-US', {
                              month: 'short',
                            }) +
                              ' ' +
                              date.toLocaleString('en-US', {
                                year: 'numeric',
                              })}
                          </th>
                        )
                      }
                    )
                  : analysisResult?.financial_statements_stock?.map(
                      (yearData, columnIndex) => {
                        yearData.year.split('-')
                        const date = new Date(
                          Number(yearData.year.split('-')[0]),
                          Number(yearData.year.split('-')[1]),
                          0
                        )
                        return (
                          <th scope="col" key={columnIndex}>
                            {date.toLocaleString('en-US', {
                              month: 'short',
                            }) +
                              ' ' +
                              date.toLocaleString('en-US', {
                                year: 'numeric',
                              })}
                          </th>
                        )
                      }
                    )}
              </th>
            </th>
          </tr>
        </thead>
        <tbody className="financial-main-table-tbody">
          {financial_statements[0]?.values
            .slice(
              0,
              firmBackground.industry === 'Finance & Insurance' ? 14 : 12
            )
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={`financial-main-table-fixed-row ${
                    row.label === 'Total Assets' ||
                    row.label === 'Total Liabilities And Equity'
                      ? 'fw-bold'
                      : ''
                  }`}
                  data-tip={
                    row.label === 'Cash and Banks'
                      ? intl.formatMessage({
                          id: 'tooltip_cash_and_banks',
                        })
                      : row.label === 'Cash And Banks'
                      ? intl.formatMessage({
                          id: 'tooltip_cash_and_banks',
                        })
                      : row.label === 'Debt Securities'
                      ? intl.formatMessage({
                          id: 'tooltip_equity_securities',
                        })
                      : row.label === 'Equity Securities'
                      ? intl.formatMessage({
                          id: 'tooltip_equity_securities',
                        })
                      : row.label === 'Debt Securities'
                      ? intl.formatMessage({
                          id: 'tooltip_debt_securities',
                        })
                      : row.label === 'Gross Loans'
                      ? intl.formatMessage({
                          id: 'tooltip_gross_loans',
                        })
                      : row.label === 'Non-performing Loans'
                      ? intl.formatMessage({
                          id: 'tooltip_non_performing_loans',
                        })
                      : row.label === 'General Reserves'
                      ? intl.formatMessage({
                          id: 'tooltip_general_reserves',
                        })
                      : row.label === 'Specific Reserves'
                      ? intl.formatMessage({
                          id: 'tooltip_specific_reserves',
                        })
                      : row.label === 'Total Assets'
                      ? intl.formatMessage({
                          id: 'tooltip_total_assets',
                        })
                      : row.label === 'Deposits'
                      ? intl.formatMessage({
                          id: 'tooltip_deposits',
                        })
                      : row.label === 'Borrowings'
                      ? intl.formatMessage({
                          id: 'tooltip_borrowings',
                        })
                      : row.label === 'Equity'
                      ? intl.formatMessage({
                          id: 'tooltip_equity',
                        })
                      : row.label === 'Total Liabilities And Equity'
                      ? intl.formatMessage({
                          id: 'tooltip_total_liabilities_and_equity',
                        })
                      : row.label === 'Guarantees'
                      ? intl.formatMessage({
                          id: 'tooltip_guarantees',
                        })
                      : row.label === 'Net FX Position'
                      ? intl.formatMessage({
                          id: 'tooltip_net_fx_position',
                        })
                      : row.label === 'Interest Incomes'
                      ? intl.formatMessage({
                          id: 'tooltip_interest_incomes',
                        })
                      : row.label === 'Interest Expenses'
                      ? intl.formatMessage({
                          id: 'tooltip_interest_expenses',
                        })
                      : row.label === 'Equity Gains and Losses'
                      ? intl.formatMessage({
                          id: 'tooltip_equity_gains_losses',
                        })
                      : row.label === 'Provisions'
                      ? intl.formatMessage({
                          id: 'tooltip_provisions',
                        })
                      : row.label === 'Income Tax'
                      ? intl.formatMessage({
                          id: 'tooltip_income_tax',
                        })
                      : row.label === 'Net Income'
                      ? intl.formatMessage({
                          id: 'tooltip_net_income',
                        })
                      : row.label === 'Regulatory Capital'
                      ? intl.formatMessage({
                          id: 'tooltip_capital',
                        })
                      : row.label === 'Risk Weighted Assets'
                      ? intl.formatMessage({
                          id: 'tooltip_rwas',
                        })
                      : row.label === 'Gross Loans'
                      ? intl.formatMessage({
                          id: 'tooltip_loans',
                        })
                      : row.label === 'Inventories'
                      ? intl.formatMessage({
                          id: 'tooltip_inventories',
                        })
                      : row.label === 'Account Receivables'
                      ? intl.formatMessage({
                          id: 'tooltip_account_receivables',
                        })
                      : row.label === 'Tangible And Intangible Assets'
                      ? intl.formatMessage({
                          id: 'tooltip_tangible_and_intangible_assets',
                        })
                      : row.label === 'Account Payables'
                      ? intl.formatMessage({
                          id: 'tooltip_account_payables',
                        })
                      : row.label === 'Debt'
                      ? intl.formatMessage({
                          id: 'Debt',
                        })
                      : row.label === 'Balancing'
                      ? intl.formatMessage({
                          id: 'tooltip_balancing',
                        })
                      : ''
                  }
                >
                  <ReactTooltip />
                  {intl.formatMessage({
                    id: row.key,
                  })}
                </td>
                <td>
                  {financial_statements.map((yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          onChange={(e) =>
                            handleInputChange(
                              e.target.value,
                              row.key,
                              columnIndex
                            )
                          }
                          defaultValue="0"
                          value={yearData.values[rowIndex].value.toLocaleString(
                            'en-US'
                          )}
                          className="financial-main-table-input"
                          pattern="^-?\d*\.?\d+"
                        />
                      </td>
                    </>
                  ))}
                </td>
                <td>
                  {analysisResult?.financial_statements ? (
                    analysisResult?.financial_statements?.map(
                      (yearData, columnIndex) => (
                        <>
                          <td key={columnIndex}>
                            <input
                              type="text"
                              defaultValue={0}
                              value={yearData.values[
                                rowIndex
                              ].value.toLocaleString('en-US')}
                              disabled
                              className="financial-main-table-input"
                            />
                          </td>
                        </>
                      )
                    )
                  ) : (
                    <>
                      {getDateDiff()?.map((item, index) => (
                        <td scope="col" key={index}>
                          <MyLoader />
                        </td>
                      ))}
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <table className="financial-main-table" style={{ marginTop: '10px' }}>
        <thead>
          <tr className="financial-main-table-tr">
            <th className="fixed-row">
              <span
                style={{ color: '#012169' }}
                className="financial-main-table-fixed-row-title"
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            <th
              className="common-row2-financial"
              style={{ display: 'flex', justifyContent: 'space-around' }}
            >
        
              {financial_statements.map((item, index) => (
                <th key={index} scope="col">
                  {new Date(item.year).toLocaleString('en-US', {
                    month: 'short',
                  }) +
                    ' ' +
                    new Date(item.year).toLocaleString('en-US', {
                      year: 'numeric',
                    })}
                </th>
              ))}
            </th>
            <th>
              <th style={{ display: 'flex', justifyContent: 'space-around' }}>
                {analysisResult?.financial_statements?.map(
                  (yearData, columnIndex) => {
                    yearData.year.split('-')
                    const date = new Date(
                      Number(yearData.year.split('-')[0]),
                      Number(yearData.year.split('-')[1]),
                      0
                    )
                    return (
                      <th scope="col" key={columnIndex}>
                        {date.toLocaleString('en-US', {
                          month: 'short',
                        }) +
                          ' ' +
                          date.toLocaleString('en-US', {
                            year: 'numeric',
                          })}
                      </th>
                    )
                  }
                )}
              </th>
            </th>
          </tr>
        </thead>
        <tbody className="financial-main-table-tbody">
          {financial_statements[0]?.values
            .slice(
              firmBackground.industry === 'Finance & Insurance' ? 14 : 12,
              financial_statements[0]?.values.length
            )
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={`financial-main-table-fixed-row ${
                    row.label === 'Net Income' ? 'fw-bold' : ''
                  }`}
                  data-tip={
                    row.label === 'Sales Of Goods and Services'
                      ? intl.formatMessage({
                          id: 'tooltip_sales_of_goods_and_services',
                        })
                      : row.label === 'Cost Of Sales Of Goods and Services Sold'
                      ? intl.formatMessage({
                          id: 'tooltip_cost_of_goods_and_services_sold',
                        })
                      : row.label === 'Depreciation And Amortization Expenses'
                      ? intl.formatMessage({
                          id: 'tooltip_depreciation_and_amortization_expenses',
                        })
                      : row.label === 'Other Operating Expenses'
                      ? intl.formatMessage({
                          id: 'tooltip_other_operating_expenses',
                        })
                      : row.label === 'Interest Incomes'
                      ? intl.formatMessage({
                          id: 'tooltip_interest_incomes',
                        })
                      : row.label === 'Equity Gains and Losses'
                      ? intl.formatMessage({
                          id: 'tooltip_equity_gains_losses',
                        })
                      : row.label === 'Interest Expenses'
                      ? intl.formatMessage({
                          id: 'tooltip_interest_expenses',
                        })
                      : row.label === 'Income Tax'
                      ? intl.formatMessage({
                          id: 'tooltip_income_tax',
                        })
                      : row.label === 'Net Income'
                      ? intl.formatMessage({
                          id: 'tooltip_net_income',
                        })
                      : row.label === 'Provisions'
                      ? intl.formatMessage({
                          id: 'tooltip_provisions',
                        })
                      : row.label === 'Regulatory Capital'
                      ? intl.formatMessage({
                          id: 'tooltip_capital',
                        })
                      : row.label === 'Risk Weighted Assets'
                      ? intl.formatMessage({
                          id: 'tooltip_rwas',
                        })
                      : ''
                  }
                >
                  <ReactTooltip />
                  {intl.formatMessage({
                    id: row.key,
                  })}
                </td>
                <td>
                  {financial_statements.map((yearData, columnIndex) => (
                    <td key={columnIndex}>
                      <input
                        type="text"
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            row.key,
                            columnIndex
                          )
                        }
                        value={
                          yearData.values[
                            rowIndex +
                              (firmBackground.industry === 'Finance & Insurance'
                                ? 14
                                : 12)
                          ].value
                        }
                        className="financial-main-table-input"
                        pattern="^-?\d*\.?\d+" // Allows negative numbers and decimals
                      />
                    </td>
                  ))}
                </td>
                <td>
                  {analysisResult?.financial_statements ? (
                    flowOrstock === true ? (
                      analysisResult?.financial_statements?.map(
                        (yearData, columnIndex) => (
                          <>
                            <td key={columnIndex}>
                              <input
                                type="text"
                                defaultValue={0}
                                value={yearData?.values[
                                  rowIndex +
                                    (firmBackground.industry ===
                                    'Finance & Insurance'
                                      ? 14
                                      : 12)
                                ]?.value.toLocaleString('en-US')}
                                disabled
                                className="financial-main-table-input"
                              />
                            </td>
                          </>
                        )
                      )
                    ) : (
                      analysisResult?.financial_statements_stock?.map(
                        (yearData, columnIndex) => (
                          <>
                            <td key={columnIndex}>
                              <input
                                type="text"
                                defaultValue={0}
                                value={yearData?.values[
                                  rowIndex +
                                    (firmBackground.industry ===
                                    'Finance & Insurance'
                                      ? 14
                                      : 12)
                                ].value.toLocaleString('en-US')}
                                disabled
                                className="financial-main-table-input"
                              />
                            </td>
                          </>
                        )
                      )
                    )
                  ) : (
                    <>
                      {getDateDiff()?.map((item, index) => (
                        <td scope="col" key={index}>
                          <MyLoader />
                        </td>
                      ))}
                    </>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )

  useEffect(() => {
    table && window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    tourState.indexTab === false && window.scrollTo(0, 0)
  }, [tourState.indexTab])

  return (
    <div>
      {isLoading ? <Spinner /> : ''}
      <div className="analysis-table-header-container">
        <div className="analysis-table-header-container-sub">
          {/* <div style={{ maxWidth: '20%' }}>
            <button
              className="btn btn-primary"
              onClick={() => handleFlowChange(true)}
            >
              Flow
            </button>
            <button
              className="btn btn-primary"
              onClick={() => handleStockChange(false)}
            >
              Stock
            </button>
          </div> */}
          {flowOrstock === true ? (
            <div className="analysis-table-header-container-table">{table}</div>
          ) : null}
          {flowOrstock === false ? <table> {table}</table> : null}
        </div>
      </div>
    </div>
  )
}

export default FinancialStatements
