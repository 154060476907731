const storeConstants = {
  GET_USER: 'GET_USER',
  UPDATE_USER: 'UPDATE_USER',

  ANALYSIS_LIST: 'ANALYSIS_LIST',
  FIRM_SLUG: 'FIRM_SLUG',

  GET_ANALYSIS_DATA_ALL: 'GET_ANALYSIS_DATA_ALL',
  GET_ANALYSIS_REAL_DATA: 'GET_ANALYSIS_REAL_DATA',
  SAVE_ANALYSIS: 'SAVE_ANALYSIS',
  TABS_ROUTER: 'TABS_ROUTER',

  GET_FIRM_BACKGROUND_DATA: 'GET_FIRM_BACKGROUND_DATA',
  GET_FIRM_LIST: 'GET_FIRM_LIST',

  FIRM_BACKGROUND_SELECT: 'FIRM_BACKGROUND_SELECT',

  CUSTOM_DATA_FETCH: 'CUSTOM_DATA_FETCH',
  CUSTOM_SCENARIO_LIST: 'CUSTOM_SCENARIO_LIST',
  GET_LAST_CUSTOM_SCENARIO: 'GET_LAST_CUSTOM_SCENARIO',
  ENABLE_CUSTOM_SCENARIO: 'ENABLE_CUSTOM_SCENARIO',
  DISABLE_CUSTOM_SCENARIO: 'DISABLE_CUSTOM_SCENARIO',
  LOADER: 'LOADER',

  DISABLE_BUTTON: 'DISABLE_BUTTON',
  DISABLE_BUTTON_FX: 'DISABLE_BUTTON_FX',
  DISABLE_BUTTON_MATURITY: 'DISABLE_BUTTON_MATURITY',

  REMOVE_OR_GET_LAST: 'REMOVE_OR_GET_LAST',

  // Real Analysis fetch
  REAL_FINANCIAL_STATEMENTS_FETCH_DATA: 'REAL_FINANCIAL_STATEMENTS_FETCH_DATA',
  REAL_FX_POSITION_FETCH_DATA: 'REAL_FX_POSITION_FETCH_DATA',
  REAL_MATURITY_SHORT_TERM_FETCH_DATA: 'REAL_MATURITY_SHORT_TERM_FETCH_DATA',
  REAL_BUSINESS_PLAN_FETCH_DATA: 'REAL_BUSINESS_PLAN_FETCH_DATA',
  REAL_CUSTOM_SCENARIO_FETCH_DATA: 'REAL_CUSTOM_SCENARIO_FETCH_DATA',
  // Fınancıal Analysis fetch

  NEW_POST_FIRM_BACKGROUND: 'NEW_POST_FIRM_BACKGROUND',
  CHANGE_CATAGORY: 'CHANGE_CATAGORY',
  CATAGORY_SUCCESS: 'CATAGORY_SUCCESS',

  //Register Login
  GET_LEADS: 'GET_LEADS',
  DELETE_LEAD: 'DELETE_LEAD',
  ADD_LEAD: 'ADD_LEAD',
  USER_LOADING: 'USER_LOADING',
  USER_INFO: 'USER_INFO',
  RESET_TOKEN: 'RESET_TOKEN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  CLEAR_LEADS: 'CLEAR_LEADS',

  //MyFirm
  GET_MY_SHOW_FIRM: 'GET_MY_SHOW_FIRM',
  ADD_FINANCIAL_DATA: 'ADD_FINANCIAL_DATA',
  ADD_REAL_DATA: 'ADD_REAL_DATA',
  ADD_REAL_RAITING_VALUATION: 'ADD_REAL_RAITING_VALUATION',
  ADD_FINANCIAL_RAITING_VALUATION: 'ADD_FINANCIAL_RAITING_VALUATION',

  SHOW_AND_CLOSE: 'SHOW_AND_CLOSE',

  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',

  PASSWORD_RESET_TOKEN_AND_UIDB64: 'PASSWORD_RESET_TOKEN_AND_UIDB64',

  PASSWORD_RESET_FAIL: 'PASSWORD_RESET_FAIL',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_CONFIRM_FAIL: 'PASSWORD_RESET_CONFIRM_FAIL',
  PASSWORD_RESET_CONFIRM_SUCCESS: 'PASSWORD_RESET_CONFIRM_SUCCESS',

  //loan request
  GET_LOAN: 'GET_LOAN',
  ADD_LOAN: 'ADD_LOAN',
  SAVE_LOAN: 'SAVE_LOAN',

  //equity request
  GET_EQUITY: 'GET_EQUITY',
  ADD_EQUITY: 'ADD_EQUITY',
  SAVE_EQUITY: 'SAVE_EQUITY',

  //joyride
  START: 'START',
  RESET: 'RESET',
  STOP: 'STOP',
  NEXT_OR_PREV: 'NEXT_OR_PREV',
  RESTART: 'RESTART',
  STDATA: 'STDATA',
  ACTIVE_TAB: 'ACTIVE_TAB',
  ACTIVE_TAB1: 'ACTIVE_TAB1',
  INDEX_TAB: 'INDEX_TAB',
  INDEX_TAB1: 'INDEX_TAB1',
  INDEX_TAB4: 'INDEX_TAB4',
  INDEX_TAB5: 'INDEX_TAB5',
  ACTIVE_TAB2: 'ACTIVE_TAB2',
  ACTIVE_TAB3: 'ACTIVE_TAB3',
  ACTIVE_TAB4: 'ACTIVE_TAB4',
  ACTIVE_TAB5: 'ACTIVE_TAB5',
  ACTIVE_TAB6: 'ACTIVE_TAB6',

  EXCEL_PUSH: 'EXCEL_PUSH',
  EXCEL_CUSTOM_PUSH: 'EXCEL_CUSTOM_PUSH',

  SET_FLOW: 'SET_FLOW',
  SET_STOCK: 'SET_STOCK',
}
export default storeConstants
