
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2"; // ChartJS library
import { useSelector } from "react-redux"; // To access Redux state
import { useIntl } from 'react-intl'
import styles from 'assets/styles/pages/report.module.scss'
// Register chart elements
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register chartJS elements
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const RealAnalysisReport = () => {
  const [selectedMetrics, setSelectedMetrics] = useState([
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ]);

  const availableMetrics = [
    "Growth Rate Of Revenues",
    "Gross Margin",
    "EBITDA Margin",
    "Profit Margin",
    "ICR",
    "DSCR",
    "ROA",
    "ROE",
    "Leverage Ratio",
  ];

  const { analysisResult } = useSelector((state) => state.analysisDataReducer);
const intl = useIntl()
  const { custom_financial_statements, financial_statements, enable_custom_scenario } = analysisResult;
  const labels = financial_statements.map((data) => data.year); // Extract years for the x-axis labels

  const [chartIndicatorsDatas, setChartIndicatorsDatas] = useState({});

  useEffect(() => {
    indicatorsTables([
      "Growth Rate Of Revenues",
      "Gross Margin",
      "EBITDA Margin",
      "Profit Margin",
      "ICR",
      "DSCR",
      "ROA",
      "ROE",
      "Leverage Ratio",
    ]);
  }, []);

  const indicatorsTables = (keyArrays) => {
    let newChartData = {};

    keyArrays.forEach((key) => {
      const dataSet = [];

      const baselineDataSet = {
        backgroundColor: "rgba(75, 192, 192, 0.2)", // Chart color
        label: "Baseline Scenario",
        data: [],
      };
      const customDataSet = {
        backgroundColor: "rgba(153, 102, 255, 0.2)", // Custom scenario color
        label: "Custom Scenario",
        data: [],
      };

      // Fetch baseline data from financial statements
      analysisResult.indicators.forEach((datas) => {
        const indicatorsData = datas.values.find((item) => item.label === key);
        if (indicatorsData) baselineDataSet.data.push(indicatorsData.value);
      });
      dataSet.push(baselineDataSet);

      // Add custom scenario data if enabled
      if (enable_custom_scenario) {
        analysisResult.custom_indicators.forEach((datas) => {
          const indicatorsData = datas.values.find((item) => item.label === key);
          if (indicatorsData) customDataSet.data.push(indicatorsData.value);
        });
        dataSet.push(customDataSet);
      }

      newChartData[key] = { labels, datasets: dataSet };
    });

    setChartIndicatorsDatas(newChartData);
  };

  const handleCheckboxChange = (metric) => {
    setSelectedMetrics((prevSelected) =>
      prevSelected.includes(metric)
        ? prevSelected.filter((item) => item !== metric)
        : [...prevSelected, metric]
    );
  };

  const handleSelectAll = () => {
    setSelectedMetrics(availableMetrics);
  };

  const handleDeselectAll = () => {
    setSelectedMetrics([]);
  };

  const createChartOptions = (titleText) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: titleText,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  };

  const table = (
    <div style={{color:"black"}}>
      <table className="rtable">
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>

            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values.slice(0, 11).map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td
                className={`fixed-row ${
                  row.label === 'Total Assets' ||
                  row.label === 'Total Liabilities And Equity'
                    ? 'fw-bold'
                    : ''
                }`}
              >
                {intl.formatMessage({
                  id: row.key,
                })}
              </td>
              {analysisResult?.financial_statements?.map(
                (yearData, columnIndex) => (
                  <>
                    <td key={columnIndex}>
                      <input
                        type="text"
                        defaultValue={0}
                        value={yearData.values[rowIndex].value}
                        disabled
                      />
                    </td>
                  </>
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <table className="rtable">
        <thead>
          <tr>
            <th className="fixed-row" style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {analysisResult?.financial_statements?.map(
              (yearData, columnIndex) => {
                yearData.year.split('-')
                const date = new Date(
                  Number(yearData.year.split('-')[0]),
                  Number(yearData.year.split('-')[1]),
                  0
                )
                return (
                  <th scope="col" key={columnIndex}>
                    {date.toLocaleString('en-US', {
                      year: 'numeric',
                    }) +
                      '-' +
                      date.toLocaleString('en-US', {
                        month: 'short',
                      })}
                  </th>
                )
              }
            )}
          </tr>
        </thead>
        <tbody>
          {financial_statements[0]?.values
            .slice(12, financial_statements[0]?.values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={`fixed-row ${
                    row.label === 'Net Income' ? 'fw-bold' : ''
                  }`}
                >
                  {intl.formatMessage({
                    id: row.key,
                  })}
                </td>
                {analysisResult?.financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )

  const customTable = custom_financial_statements[0]?.values && (
    <div style={{color:"black"}}>
      <table className="rtable">
        <thead>
          <tr >
            <th style={{ width: '300px' }}>
              <span
                className="financial-main-table-fixed-row-title"
                style={{ color: '#012169' }}
              >
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(0, 11)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td
                  className={
                    row.label === 'Total Assets' ||
                    row.label === 'Total Liabilities And Equity'
                      ? 'fw-bold'
                      : ''
                  }
                >
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData.values[rowIndex].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
      <table className="rtable">
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={row.label === 'Net Income' ? 'fw-bold' : ''}>
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )


  return (
    <div>
      <div className={styles.filters}>
        <div className={styles.filters_charts}>
        <button onClick={handleSelectAll}>Tümünü Seç
        </button>
        <button onClick={handleDeselectAll}>Tümünün Seçimini Kaldır</button>
        </div>
  
        <div className={styles.checkbox_group}>
          {availableMetrics.map((metric) => (
            <div key={metric}>
              <input
                type="checkbox"
                id={metric}
                checked={selectedMetrics.includes(metric)}
                onChange={() => handleCheckboxChange(metric)}
              />
              <label htmlFor={metric}>{metric}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="row justify-content-center mt-5">
  {selectedMetrics.includes("Growth Rate Of Revenues") &&
    chartIndicatorsDatas["Growth Rate Of Revenues"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar
          options={createChartOptions("Growth Rate Of Revenues")}
          data={chartIndicatorsDatas["Growth Rate Of Revenues"]}
        />
      </div>
    )}
  {selectedMetrics.includes("Growth Rate Of Revenues") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("Gross Margin") &&
    chartIndicatorsDatas["Gross Margin"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar
          options={createChartOptions("Gross Margin")}
          data={chartIndicatorsDatas["Gross Margin"]}
        />
      </div>
    )}
  {selectedMetrics.includes("Gross Margin") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("EBITDA Margin") &&
    chartIndicatorsDatas["EBITDA Margin"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar
          options={createChartOptions("EBITDA Margin")}
          data={chartIndicatorsDatas["EBITDA Margin"]}
        />
      </div>
    )}
  {selectedMetrics.includes("EBITDA Margin") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("Profit Margin") &&
    chartIndicatorsDatas["Profit Margin"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar
          options={createChartOptions("Profit Margin")}
          data={chartIndicatorsDatas["Profit Margin"]}
        />
      </div>
    )}
  {selectedMetrics.includes("Profit Margin") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("ICR") &&
    chartIndicatorsDatas["ICR"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar options={createChartOptions("ICR")} data={chartIndicatorsDatas["ICR"]} />
      </div>
    )}
  {selectedMetrics.includes("ICR") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("DSCR") &&
    chartIndicatorsDatas["DSCR"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar options={createChartOptions("DSCR")} data={chartIndicatorsDatas["DSCR"]} />
      </div>
    )}
  {selectedMetrics.includes("DSCR") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("ROA") &&
    chartIndicatorsDatas["ROA"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar options={createChartOptions("ROA")} data={chartIndicatorsDatas["ROA"]} />
      </div>
    )}
  {selectedMetrics.includes("ROA") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("ROE") &&
    chartIndicatorsDatas["ROE"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar options={createChartOptions("ROE")} data={chartIndicatorsDatas["ROE"]} />
      </div>
    )}
  {selectedMetrics.includes("ROE") && (
    <hr style={{ borderTop: "2px solid black", width: "100%" }} />
  )}

  {selectedMetrics.includes("Leverage Ratio") &&
    chartIndicatorsDatas["Leverage Ratio"] && (
      <div className="col-12 col-md-6 mb-4">
        <Bar
          options={createChartOptions("Leverage Ratio")}
          data={chartIndicatorsDatas["Leverage Ratio"]}
        />
      </div>
    )}
  {selectedMetrics.includes("Leverage Ratio") && (
    <hr style={{ borderTop: "2px solid black", width: "80%" }} />
  )}
</div>


      <div className="analysis-table-header-container-sub-text">
                      <div
                        className={`${
                          styles.report_container_main_header_common
                        } ${'col-12 text-left mt-5 mb-3'}`}
                        style={{ width: '98%' }}
                      >
                        III.{' '}
                        {intl.formatMessage({
                          id: 'FINANCIAL_CHART.TITLE',
                        })}
                      </div>
                    </div>
      {table}
      <br />
      <div className="analysis-table-header-container-sub">
                  <div
                    className="analysis-table-header-container-sub-text"
                    style={{ width: '100%' }}
                  >
                    {custom_financial_statements[0]?.values && (
                      <div
                        className={`${
                          styles.report_container_main_header_common
                        } ${'col-12 text-left mt-5 mb-3'}`}
                        style={{ width: '98%' }}
                      >
                        IV.{' '}
                        {intl.formatMessage({
                          id: 'FINANCIAL_CHART.CUSTOM_TABLE_TITLE',
                        })}
                      </div>
                    )}
                  </div>
                </div>
      {customTable}
      
      {/* <table className="rtable">
        <thead>
          <tr>
            <th style={{ width: '300px' }}>
              <span style={{ color: '#012169' }}>
                {intl.formatMessage({
                  id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
                })}
              </span>
            </th>
            {custom_financial_statements?.map((yearData, columnIndex) => {
              yearData.year.split('-')
              const date = new Date(
                Number(yearData.year.split('-')[0]),
                Number(yearData.year.split('-')[1]),
                0
              )
              return (
                <th scope="col" key={columnIndex}>
                  {date.toLocaleString('en-US', {
                    year: 'numeric',
                  }) +
                    '-' +
                    date.toLocaleString('en-US', {
                      month: 'short',
                    })}
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {custom_financial_statements[0]?.values
            .slice(12, custom_financial_statements[0].values.length)
            .map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td className={row.label === 'Net Income' ? 'fw-bold' : ''}>
                  {row.label}
                </td>
                {analysisResult?.custom_financial_statements?.map(
                  (yearData, columnIndex) => (
                    <>
                      <td key={columnIndex}>
                        <input
                          type="text"
                          defaultValue={0}
                          value={yearData?.values[rowIndex + 12].value}
                          disabled
                        />
                      </td>
                    </>
                  )
                )}
              </tr>
            ))}
        </tbody>
      </table> */}
    </div>
  );
};

export default RealAnalysisReport;