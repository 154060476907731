import { combineReducers } from 'redux'
import auth from 'redux/reducers/auth'
import errors from 'redux/reducers/errors'
import myshowfirmReducer from 'redux/reducers/myshowfirmReducer'
import user from 'redux/reducers/user'
import analysisDataReducer from 'redux/reducers/analysisDataReducer'
import getUser from 'redux/reducers/getUser'
import getFirmList from 'redux/reducers/getFirmList'
import loanRequest from './reducers/loanRequest'
import equityRequest from './reducers/equityRequest'
import joyRide from './reducers/joyRide'

const rootReducer = combineReducers({
  auth,
  user,
  getUser,
  analysisDataReducer,
  errors,
  myfirm: myshowfirmReducer,
  getFirmList,
  loanRequest,
  equityRequest,
  joyRide,
})
export default rootReducer
