import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useIntl } from 'react-intl'
import {
  country,
  industry,
  decimal,
  currencySym,
} from 'services/index'
import { firmBackgroundSelect } from 'redux/actions/analysisDataActions'
import { fetchAllAnalysis } from 'redux/actions/getAnalysisDataAll'
import {
  analysisList,
  analysisTablesByYearAndIndustry,
  analysisTablesByYearAndIndustryDefault,
  createFirm,
  getFirmDetail,
  getFirmList,
  updateFirm,
} from 'redux/ApiCalls'
import storeConstants from 'redux/constants/actionTypes'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs, { locale } from 'dayjs'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material'

import utc from 'dayjs/plugin/utc'

import ru from 'dayjs/locale/ru'
import tr from 'dayjs/locale/tr'
import pt from 'dayjs/locale/pt'
import fr from 'dayjs/locale/fr'
import zh from 'dayjs/locale/zh'
import es from 'dayjs/locale/es'
import de from 'dayjs/locale/de'

import {
  ruRU,
  trTR,
  ptBR,
  frFR,
  zhCN,
  deDE,
  esES,
  enUS,
} from '@mui/x-date-pickers/locales'

dayjs.extend(utc)

const Firmbackground = ({ changePage }) => {
  const { firmBackground, analysisListResult, firmSlug, tabsRouter } =
    useSelector((state) => state.analysisDataReducer)
  const dateStart = dayjs.unix(firmBackground?.foreCastStartDate / 1000)

  const dateEnd = dayjs.unix(firmBackground?.foreCastEndDate / 1000)
  const dateLastYear = dayjs.unix(firmBackground?.decemberOfLastYear / 1000)
  const fiscalMonth = dayjs.unix(firmBackground?.fiscalYear / 1000)
  const dateBusinessStartYear = dayjs.unix(
    firmBackground?.start_of_operations_year / 1000
  )
  const freq = firmBackground?.projection_frequency

  const [selectedDate, setSelectedDate] = useState(dateStart)
  const [selectedDateEnd, setSelectedDateEnd] = useState(dateEnd)
  const [selectedDateBusinessStartYear, setSelectedDateBusinessStartYear] =
    useState(dateBusinessStartYear)

  const [decemberOfPreviousYear, setDecemberOfPreviousYear] =
    useState(dateLastYear)
 
  const [flowOrstock, setFlowOrstock] = useState(firmBackground?.flowOrstock)

  const [selectedFrequency, setSelectedFrequency] = useState(freq)

  const today = dayjs()

  const [minEndDate, setMinEndDate] = useState(selectedDateEnd.startOf('year'))

  const maxEndDate = selectedDateEnd.add(15, 'year').endOf('year')

  const minStartDate = today.subtract(10, 'year').startOf('year')

  const lastDayOfNextMonthStartDate = today.add(-1, 'month').endOf('month')

  const [selectedDateMonth, setSelectedDateMonth] = useState(fiscalMonth)

  const params = useParams()
  const dispatch = useDispatch()
  const intl = useIntl()

  const [error, setError] = useState(false)

  const [firmNameError, setFirmNameError] = useState(false)

  const [errorDateMessage, setErrorDateMessage] = useState(null)

  const [showWarning, setShowWarning] = useState(false)

  const [firmName, setFirmName] = useState('')

  const { token } = useSelector((state) => state.auth)

  const firmSlugOrFirmSlugReducer = params?.firmSlug
    ? params?.firmSlug
    : firmSlug




  useEffect(() => {
    setSelectedDateEnd(dateEnd)
    setSelectedDate(dateStart)
    setSelectedFrequency(freq)
    setSelectedDateBusinessStartYear(dateBusinessStartYear)

    setDecemberOfPreviousYear(dateLastYear)
    setSelectedDateMonth(fiscalMonth)
    setFirmName(firmBackground?.name)
    if (firmBackground?.flowOrstock !== flowOrstock) {
      setFlowOrstock(firmBackground?.flowOrstock)
    }
  }, [firmBackground])

  const handleDateChangeMonth = (newDate) => {
    const fiscalYear = newDate.unix() * 1000
    setSelectedDateMonth(newDate)
    const newFirmbackground = {
      ...firmBackground,
      fiscalYear: fiscalYear,
    }
    dispatch(firmBackgroundSelect(newFirmbackground))
  }

  useEffect(() => {
    // Get the month and year of the forecast start date
    const forecastStartMonth = dateStart.month()
    const forecastStartYear = dateStart.year()

    // Get the fiscal year-end month
    const fiscalYearEndMonth = fiscalMonth.month()

    // Check if the forecast start month is before or after the fiscal year-end month
    if (forecastStartMonth <= fiscalYearEndMonth) {
      // If before or equal, the first historical period will be in the previous calendar year
      const previousYear = forecastStartYear - 1
      const firstHistoricalPeriod = dayjs()
        .year(previousYear)
        .month(fiscalYearEndMonth)

      // Set the timestamp for the last day of the fiscal year in the previous calendar year
      const lastDayOfFiscalYear = firstHistoricalPeriod.endOf('month').valueOf()

      const newFirmbackground = {
        ...firmBackground,
        decemberOfLastYear: lastDayOfFiscalYear,
      }
      setDecemberOfPreviousYear(lastDayOfFiscalYear)
      dispatch(firmBackgroundSelect(newFirmbackground))
    } else {
      // If after, the first historical period will be in the current calendar year
      const firstHistoricalPeriod = dayjs()
        .year(forecastStartYear)
        .month(fiscalYearEndMonth)

      // Set the timestamp for the last day of the fiscal year in the current calendar year
      const lastDayOfFiscalYear = firstHistoricalPeriod.endOf('month').valueOf()

      setDecemberOfPreviousYear(lastDayOfFiscalYear)
      const newFirmbackground = {
        ...firmBackground,
        decemberOfLastYear: lastDayOfFiscalYear,
      }
      dispatch(firmBackgroundSelect(newFirmbackground))
    }
  }, [firmBackground?.foreCastStartDate, firmBackground?.fiscalYear])
  // mali yıl sonu eğer aralık ayı sonu ise kullanıcı tarihsel
  // dönemin ilk başlangıcını tahmin başlangıcından geriye
  // doğruki ilk mali yıl sonu yapabilir miyiz.
  // Örneğin mali yıl sonu haziran ve tahmin 2023 mart ayında başlıyorsa,
  // ilk tarihsel dönem 2022 haziran olacak. Başka örnek, Eğer mali yıl sonu mart ayı
  // ve tahmin başlangıcı
  // 2023 eylül ise, ilk tarihsel dönem 2023 mart ayı olacak.
  useEffect(() => {
    if (firmSlugOrFirmSlugReducer.length > 0 && token) {
      getFirmDetail(token, firmSlugOrFirmSlugReducer).then(({ data }) => {
        dispatch({
          type: storeConstants.FIRM_BACKGROUND_SELECT,
          payload: {
            ...data,
            foreCastStartDate: data.foreCastStartDate * 1000,
            decemberOfLastYear: data.decemberOfLastYear * 1000,
            foreCastEndDate: data.foreCastEndDate * 1000,
            fiscalYear: data.fiscalYear * 1000,
            start_of_operations_year: data.start_of_operations_year * 1000,
          },
        })
        dispatch({
          type: storeConstants.TABS_ROUTER,
          payload: false,
        })
      })
    }
  }, [tabsRouter])
  useEffect(() => {
    if (token && firmSlugOrFirmSlugReducer.length > 0) {
      analysisList(token, firmSlugOrFirmSlugReducer)
        .then((res) => {
          dispatch({
            type: storeConstants.ANALYSIS_LIST,
            payload: res.data.results,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [firmSlugOrFirmSlugReducer.length])

  const onChange = (e) => {
    const dateObject = new Date(selectedDate)
    const timestamp = dateObject.getTime()
    const newFirmbackground = Object.assign({}, firmBackground)
    newFirmbackground[e.target.name] =
      e.target.name === 'foreCastStartDate' ||
      e.target.name === 'decemberOfLastYear' ||
      e.target.name === 'start_of_operations_year' ||
      e.target.name === 'fiscalYear' ||
      e.target.name === 'foreCastEndDate'
        ? Number(e.target.value)
        : e.target.value
    dispatch(firmBackgroundSelect(newFirmbackground))
    // genarateYears()
  }

  const nameOnChange = (e) => {
    setFirmName(e.target.value)
    dispatch(firmBackgroundSelect({ ...firmBackground, name: e.target.value }))
  }
  const start_of_operations_year = dayjs(
    firmBackground.start_of_operations_year
  ).unix()

  const previousMonth = new Date().getMonth()
  const y = Array.from(new Array(12), (val, index) => previousMonth + index)

  useEffect(() => {
    getFirmList(token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        })
      })
      .catch((e) => console.log(e))
  }, [])

  useEffect(() => {
    if (showWarning) {
      setErrorDateMessage('minDate')
    } else {
      setErrorDateMessage(null)
    }
  }, [showWarning])

  const handleDatePickerError = (newError) => {
    setErrorDateMessage(newError)
  }

  const errorMessage = useMemo(() => {
    switch (errorDateMessage) {
      case 'maxDate':
      case 'minDate': {
        return 'Warning: End date cannot be less than start date'
      }

      case 'invalidDate': {
        return 'Your date is not valid'
      }

      default: {
        return ''
      }
    }
  }, [errorDateMessage])

  const handleDateChangeStart = (newDate) => {
    const dateObjectStart = new Date(newDate)
    const year = dateObjectStart.getFullYear()
    const month = dateObjectStart.getMonth()

    const lastDayOfMonth = new Date(year, month + 1, 0)
    lastDayOfMonth.setHours(0, 0, 0, 0)

    const timestampInSeconds = Math.floor(lastDayOfMonth.getTime())
    const lastDecember = new Date(year - 1, 11, 31, 0, 0, 0, 0)

    const selectedEndDate = new Date(selectedDateEnd)
    const yearEnd = selectedEndDate.getFullYear()
    const monthEnd = selectedEndDate.getMonth()

    if (year > yearEnd || (year === yearEnd && month > monthEnd)) {
      setShowWarning(true)
      setErrorDateMessage('Start date must be before the end date.')
    } else if (year < yearEnd || (year === yearEnd && month < monthEnd)) {
      setShowWarning(false)

      let foreCastStartDate = timestampInSeconds
      // Check if the selected start date is in the same month as the selected end date
      // if (month === monthEnd) {
      //   foreCastStartDate = Math.floor(lastDecember.getTime())
      // }
      const newFirmbackground = {
        ...firmBackground,
        foreCastStartDate: foreCastStartDate,
        decemberOfLastYear: lastDecember.getTime(),
        start_of_operations_year: timestampInSeconds,
      }

      dispatch(firmBackgroundSelect(newFirmbackground))
      const newMinEndDate = dayjs(newDate).startOf('year')
      setMinEndDate(newMinEndDate)
    } else {
      setShowWarning(false)
    }
  }
  // if (start_of_operations_year <= firmBackground.foreCastStartDate) {
  //   // Your specific logic or actions to be performed when the condition is true.
  //   // Replace the comment below with your actual code.
  //   console.log(
  //     'Start of operations year is less than or equal to foreCastStartDate'
  //   )
  //   // Add more code as needed...
  // }
  const handleDateChangeEnd = (newDate) => {
    const dateObjectEnd = new Date(newDate)
    const yearEnd = dateObjectEnd.getFullYear()
    const monthEnd = dateObjectEnd.getMonth()

    const dateObjectStart = new Date(firmBackground.foreCastStartDate)
    const yearStart = dateObjectStart.getFullYear()
    const monthStart = dateObjectStart.getMonth()

    if (
      yearEnd < yearStart ||
      (yearEnd === yearStart && monthEnd <= monthStart)
    ) {
      setShowWarning(true)
      setErrorDateMessage(
        'End date cannot be equal to or earlier than the start date.'
      )
    } else {
      if (yearEnd === yearStart && monthEnd === monthStart) {
        setShowWarning(true)
        setErrorDateMessage('End date cannot be equal to the start date.')
      } else {
        setShowWarning(false)
        const lastDay = new Date(yearEnd, monthEnd + 1, 0)
        const timestampEnd = lastDay.getTime()

        const newFirmbackground = {
          ...firmBackground,
          foreCastEndDate: timestampEnd,
        }
        dispatch(firmBackgroundSelect(newFirmbackground))
        setSelectedDateEnd(newDate)
      }
    }
  }

  const monthsToDisable = []
  const quartersToDisable = [0, 1, 3, 4, 6, 7, 9, 10]
  const semiAnnualToDisable = [0, 1, 2, 3, 4, 6, 7, 8, 9, 10]
  const annualToDisable = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  const shouldDisableMonth = (date) => {
    const month = date.month()
    switch (selectedFrequency) {
      case 'M':
        return monthsToDisable.includes(month)
      case 'Q':
        return quartersToDisable.includes(Math.floor(month))
      case '6M':
        return semiAnnualToDisable.includes(Math.floor(month))
      case 'A':
        return annualToDisable.includes(month)
      default:
        return false
    }
  }

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value)
    const newFirmbackground = {
      ...firmBackground,
      projection_frequency: e.target.value,
    }
    dispatch(firmBackgroundSelect(newFirmbackground))
  }

  const storedLang = JSON.parse(
    localStorage.getItem('selectedLang')
  ).selectedLang

  dayjs.locale(storedLang)
  const [selectedLang, setSelectedLang] = useState(
    JSON.parse(localStorage.getItem('selectedLang')).selectedLang
  )

  const handleFlowChange = () => {
    const newFirmbackground = {
      ...firmBackground,
      flowOrstock: true,
    }
    dispatch(firmBackgroundSelect(newFirmbackground))
  }

  const handleStockChange = () => {
    const newFirmbackground = {
      ...firmBackground,
      flowOrstock: false,
    }
    dispatch(firmBackgroundSelect(newFirmbackground))
  }

  const maxBusinessStartYear = selectedDate
  const minBusinessStartYear = dayjs().subtract(50, 'years').startOf('year')

  const handleDateChangeBusinessStartYear = (newDate) => {
    // Assuming minBusinessStartYear is defined somewhere in your code
    const minBusinessStartYear = dayjs().subtract(50, 'years').startOf('year')

    // Calculate the start of the year for the new date
    const newBusinessStartDate = dayjs(newDate).startOf('year')

    if (newBusinessStartDate.isBefore(minBusinessStartYear)) {
      // If the new date is before the minimum allowed date, set it to the minimum allowed date
      setSelectedDateBusinessStartYear(minBusinessStartYear)
      const timestampMinBusinessStartYear = minBusinessStartYear.valueOf()
      const newFirmbackground = {
        ...firmBackground,
        start_of_operations_year: minBusinessStartYear,
      }
      dispatch(firmBackgroundSelect(newFirmbackground))
    } else {
      // Otherwise, set the selected date to the new date
      setSelectedDateBusinessStartYear(newDate)
      const timestampNewDate = newDate.valueOf()
      const newFirmbackground = {
        ...firmBackground,
        start_of_operations_year: timestampNewDate,
      }
      dispatch(firmBackgroundSelect(newFirmbackground))
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()

    if (firmName.length === 0) {
      setFirmNameError(true)
      return
    }

    if (firmBackground.industry.length === 0) {
      setError(true)
      return
    } else {
      dispatch(firmBackgroundSelect({ ...firmBackground, name: firmName }))
      let analysisId = analysisListResult[0]?.id
      if (analysisId && firmSlugOrFirmSlugReducer) {
        analysisTablesByYearAndIndustry(
          firmBackground.foreCastStartDate,
          firmBackground.decemberOfLastYear,
          firmBackground.foreCastEndDate,
          firmBackground.projection_frequency,
          firmBackground.industry,
          firmBackground.fiscalYear,

          // start_of_operations_year,
          token,
          analysisId
        )
          .then(({ data }) => {
            Object.values(data).map((item) =>
              item.sort(
                (a, b) =>
                  new Date(a.year).getTime() - new Date(b.year).getTime()
              )
            )
            dispatch(fetchAllAnalysis(data))
            changePage()
          })
          .catch((err) => err)
      } else {
        analysisTablesByYearAndIndustryDefault(
          firmBackground,
          token
        )
          .catch((err) => {
            console.log(err)
          })
          .then(({ data }) => {
            Object.values(data).map((item) =>
              item.sort(
                (a, b) =>
                  new Date(a.year).getTime() - new Date(b.year).getTime()
              )
            )
            dispatch(fetchAllAnalysis(data))
            changePage()
          })
          .catch((err) => err)
      }

      console.log("flowOrstock",flowOrstock)

      if (firmSlugOrFirmSlugReducer.length === 0 && token) {
        createFirm(token, {
          ...firmBackground,
          name: firmBackground.name,
          flowOrstock: firmBackground.flowOrstock,
          foreCastStartDate: firmBackground.foreCastStartDate / 1000,
          decemberOfLastYear: decemberOfPreviousYear / 1000,
          foreCastEndDate: firmBackground.foreCastEndDate / 1000,
          fiscalYear: firmBackground.fiscalYear / 1000,
          start_of_operations_year,
        }).then((data) => {
          toast.success('Firm created')
          dispatch({
            type: storeConstants.FIRM_SLUG,
            payload: data.data.slug,
          })
        })
      } else if (firmSlugOrFirmSlugReducer.length > 0 && token) {
        updateFirm(token, firmSlugOrFirmSlugReducer, {
          ...firmBackground,
          name: firmBackground.name,
          flowOrstock: firmBackground.flowOrstock,
          foreCastStartDate: firmBackground.foreCastStartDate / 1000,
          decemberOfLastYear: decemberOfPreviousYear / 1000,
          foreCastEndDate: firmBackground.foreCastEndDate / 1000,
          fiscalYear: firmBackground.fiscalYear / 1000,
          start_of_operations_year,
        })
      }
    }
  }

  return (
    <Grid container spacing={2} className="firm_background_form_grid">
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          error={firmNameError}
          required
          id="firm-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.COUNTRY',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.FIRM_NAME' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          className="step2"
          onChange={nameOnChange}
          required
          error={firmNameError}
          value={firmBackground.name}
          fullWidth
          id="fullWidth"
        />
      </Grid>
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          id="country-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.COUNTRY',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.COUNTRY' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth className="step3">
          <Select
            labelId="country-label"
            id="country-select"
            value={firmBackground.country}
            name="country"
            onChange={onChange}
          >
            {country.map((option, i) => (
              <MenuItem value={option.value} key={i}>
                {intl.formatMessage({ id: option.label })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          error={error}
          required
          id="industry-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.INDUSTTRY',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.INDUSTTRY' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6} className="step4">
        <FormControl fullWidth required>
          <Select
            id="industry-select"
            value={firmBackground.industry}
            name="industry"
            onChange={onChange}
            error={error}
          >
            {industry.map((option, i) => (
              <MenuItem value={option.value} key={i}>
                {intl.formatMessage({ id: option.label })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          id="audit-label"
          className="firmbackgroudn_form_label"
      
        >
          What is fiscal year end:
        </InputLabel>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        className="step9"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={
            selectedLang === 'ru'
              ? ru
              : selectedLang === 'tr'
              ? tr
              : selectedLang === 'pt'
              ? pt
              : selectedLang === 'fr'
              ? fr
              : selectedLang === 'cn'
              ? zh
              : selectedLang === 'es'
              ? es
              : selectedLang === 'de'
              ? de
              : 'undefined'
          }
          localeText={
            selectedLang === 'ru'
              ? ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'tr'
              ? trTR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'pt'
              ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'fr'
              ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'cn'
              ? zhCN.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'es'
              ? esES.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'de'
              ? deDE.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'sa'
              ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
              : 'undefined'
          }
        >
          <DatePicker
            views={['month']}
            value={selectedDateMonth}
            onChange={handleDateChangeMonth}
          />
        </LocalizationProvider>

        {/* <Grid style={{ display: 'flex' }}>
          <Grid item xs={12} md={6} className="firmbackground_label_grid">
            <InputLabel id="audit-label" className="firmbackground_form_label">
              flow stock?:
            </InputLabel>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={flowOrstock === true}
                onChange={handleFlowChange}
              />
            }
            label="Flow"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={flowOrstock === false}
                onChange={handleStockChange}
              />
            }
            label="Stock"
          />
        </Grid> */}
      </Grid>
      {errorDateMessage !== null ? (
        <Grid item xs={12} md={4} className="firmbackground_label_grid">
          <InputLabel
            id="forecast-label-error"
            className="firmbackgroudn_form_label"
            data-tip={intl.formatMessage({ id: 'FIRMBACKGROUND.FORECAST' })}
          >
            {intl.formatMessage({ id: 'FIRMBACKGROUND.FORECAST' })}
          </InputLabel>
        </Grid>
      ) : (
        <Grid item xs={12} md={4} className="firmbackground_label_grid">
          <InputLabel
            id="forecast-label"
            className="firmbackgroudn_form_label"
            data-tip={intl.formatMessage({ id: 'FIRMBACKGROUND.FORECAST' })}
          >
            {intl.formatMessage({ id: 'FIRMBACKGROUND.FORECAST' })}
          </InputLabel>
        </Grid>
      )}
      <Grid item xs={12} md={2} className="step6">
        <FormControl fullWidth>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={
              selectedLang === 'ru'
                ? ru
                : selectedLang === 'tr'
                ? tr
                : selectedLang === 'pt'
                ? pt
                : selectedLang === 'fr'
                ? fr
                : selectedLang === 'cn'
                ? zh
                : selectedLang === 'es'
                ? es
                : selectedLang === 'de'
                ? de
                : 'undefined'
            }
            localeText={
              selectedLang === 'ru'
                ? ruRU.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'tr'
                ? trTR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'pt'
                ? ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'fr'
                ? frFR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'cn'
                ? zhCN.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'es'
                ? esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'de'
                ? deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'sa'
                ? enUS.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : 'undefined'
            }
          >
            <DatePicker
              label={intl.formatMessage({
                id: 'FIRMBACKGROUND.START_DATE_LABEL',
              })}
              className="step6-month-class"
              views={['month', 'year']}
              value={selectedDate}
              onChange={handleDateChangeStart}
              minDate={minStartDate}
              maxDate={lastDayOfNextMonthStartDate}
              id="month-id"
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2} className="step7">
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            {intl.formatMessage({ id: 'FIRMBACKGROUND.FREQUENCY' })}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedFrequency}
            label="Frequency"
            onChange={handleFrequencyChange}
          >
            <MenuItem value="M">
              {intl.formatMessage({ id: 'FREQUENCY.MONTHS' })}
            </MenuItem>
            <MenuItem value="Q">
              {intl.formatMessage({ id: 'FREQUENCY.QUARTERS' })}
            </MenuItem>
            <MenuItem value="6M">
              {intl.formatMessage({ id: 'FREQUENCY.SEMI_ANNUAL' })}
            </MenuItem>
            <MenuItem value="A">
              {intl.formatMessage({ id: 'FREQUENCY.ANNUAL' })}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={2} className="step8">
        <FormControl fullWidth>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={
              selectedLang === 'ru'
                ? ru
                : selectedLang === 'tr'
                ? tr
                : selectedLang === 'pt'
                ? pt
                : selectedLang === 'fr'
                ? fr
                : selectedLang === 'cn'
                ? zh
                : selectedLang === 'es'
                ? es
                : selectedLang === 'de'
                ? de
                : 'undefined'
            }
            localeText={
              selectedLang === 'ru'
                ? ruRU.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'tr'
                ? trTR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'pt'
                ? ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'fr'
                ? frFR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'cn'
                ? zhCN.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'es'
                ? esES.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'de'
                ? deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : selectedLang === 'sa'
                ? enUS.components.MuiLocalizationProvider.defaultProps
                    .localeText
                : 'undefined'
            }
          >
            <DatePicker
              label={intl.formatMessage({
                id: 'FIRMBACKGROUND.END_DATE_LABEL',
              })}
              views={['month', 'year']}
              value={selectedDateEnd}
              // shouldDisableMonth={shouldDisableMonth}
              onError={handleDatePickerError}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  helperText: errorMessage,
                },
              }}
              minDate={minEndDate}
              maxDate={maxEndDate}
              onChange={handleDateChangeEnd}
              className={errorDateMessage ? 'datePickerError' : ''}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          id="currency-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.BUSINESS_START_YEAR',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.BUSINESS_START_YEAR' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6} className="step9">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={
            selectedLang === 'ru'
              ? ru
              : selectedLang === 'tr'
              ? tr
              : selectedLang === 'pt'
              ? pt
              : selectedLang === 'fr'
              ? fr
              : selectedLang === 'cn'
              ? zh
              : selectedLang === 'es'
              ? es
              : selectedLang === 'de'
              ? de
              : 'undefined'
          }
          localeText={
            selectedLang === 'ru'
              ? ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'tr'
              ? trTR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'pt'
              ? ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'fr'
              ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'cn'
              ? zhCN.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'es'
              ? esES.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'de'
              ? deDE.components.MuiLocalizationProvider.defaultProps.localeText
              : selectedLang === 'sa'
              ? enUS.components.MuiLocalizationProvider.defaultProps.localeText
              : 'undefined'
          }
        >
          <DatePicker
            views={['year']}
            value={selectedDateBusinessStartYear}
            onChange={handleDateChangeBusinessStartYear}
            minDate={minBusinessStartYear}
            maxDate={maxBusinessStartYear}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          id="currency-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.CURRENCY',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.CURRENCY' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6} className="step9">
        <FormControl fullWidth>
          <Select
            labelId="currency-select"
            id="currency-select"
            value={firmBackground.currency_sym}
            name="currency_sym"
            onChange={onChange}
          >
            {currencySym.map((option, i) => (
              <MenuItem value={option.label} key={i}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel
          id="decimal-label"
          className="firmbackgroudn_form_label"
          data-tip={intl.formatMessage({
            id: 'tooltip.FIRMBACKGROUND.DECIMAL',
          })}
        >
          {intl.formatMessage({ id: 'FIRMBACKGROUND.DECIMAL' })}
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6} className="step10 firmbackground_label_grid">
        <FormControl fullWidth>
          <Select
            labelId="decimal-select"
            id="decimal-select"
            value={firmBackground.decimal}
            name="decimal"
            onChange={onChange}
          >
            {decimal?.map((option, i) => (
              <MenuItem value={option?.value} key={i}>
                {intl.formatMessage({ id: option?.label })}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={4} className="firmbackground_label_grid">
        <InputLabel id="audit-label" className="firmbackground_form_label">
          flow stock?:
        </InputLabel>
      </Grid>
      <Grid item xs={12} md={6} className="firmbackground_label_grid">
        <FormControlLabel
          control={
            <Checkbox
              checked={flowOrstock === true}
              onChange={handleFlowChange}
            />
          }
          label="Flow"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={flowOrstock === false}
              onChange={handleStockChange}
            />
          }
          label="Stock"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="text-center">
          <button
            disabled={errorDateMessage !== null || showWarning}
            className="btn btn-primary analysis_button mt-3"
            type="submit"
            onClick={onSubmit}
          >
            {intl.formatMessage({ id: 'FIRMBACKGROUND.PROCED_BUTTON' })}
          </button>
        </div>
      </Grid>
    </Grid>
  )
}

export default Firmbackground
