import { useIntl } from 'react-intl'



import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import storeConstants from '../redux/constants/actionTypes'

const TabsRouter = ({ activeTab, setActiveTab, isLoading }) => {
  const tourState = useSelector((state) => state.joyRide)

  const dispatch = useDispatch()

  const intl = useIntl()

  const { financial_statements, analysisListResult } = useSelector(
    (state) => state.analysisDataReducer
  )

  const reportButtonControl = () => {
    const findData = financial_statements.find((item) =>
      item.values.find((value) => {
        if (isNaN(value.value)) {
          let numberWithoutComma = value.value.replace(/,/g, '')
          return Number(numberWithoutComma) > 0
        } else {
          return value.value > 0
        }
      })
    )

    if (findData) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (!localStorage.getItem('tour')) {
      dispatch({ type: storeConstants.START })
    }
  }, [])
  const tabsNames = [
    'TABS_ROUTER.FIRMBACKGROUND',
    'TABS_ROUTER.FINANCIAL_STATEMENTS',
    'TABS_ROUTER.FX_POSITION',
    'TABS_ROUTER.MATURITY_SHORT_TERM',
    'TABS_ROUTER.BUSINESS_PLAN',
    'TABS_ROUTER.CUSTOM_SCENARIO',
    'TABS_ROUTER.REPORT',
  ]
  const FIRMBACKGROUND_STEPS = [
    {
      target: '.active',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.FIRMBACKGROUND',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FIRMBACKGROUND',
      }),
      disableBeacon: true,
    },
    {
      target: '.tab2',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.FINANCIAL_STATEMENTS',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FINANCIAL_STATEMENTS',
      }),
      disableBeacon: true,
    },

    {
      target: '.tab3',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.FX_POSITION',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FX_POSITION',
      }),
      disableBeacon: true,
    },
    {
      target: '.tab4',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.MATURITY_SHORT_TERM',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.MATURITY_SHORT_TERM',
      }),
      disableBeacon: true,
    },
    {
      target: '.tab5',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.BUSINESS_PLAN',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.BUSINESS_PLAN',
      }),
      disableBeacon: true,
    },
    {
      target: '.tab6',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.CUSTOM_SCENARIO',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.CUSTOM_SCENARIO',
      }),
      disableBeacon: true,
    },
    {
      target: '.tab7',
      title: intl.formatMessage({
        id: 'TABS_ROUTER.REPORT',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.REPORT',
      }),
      disableBeacon: true,
    },

    {
      target: '.step1',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.FIRM_NAME',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.FIRM_NAME',
      }),
      styles: {
        spotlight: {
          position: 'relative !important',

          left: '1000px !important',
          right: '1000px',
        },
      },
    },
    {
      target: '.step2',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.COUNTRY',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.COUNTRY',
      }),
    },
    {
      target: '.step3',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.INDUSTTRY',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.INDUSTTRY',
      }),
    },
    {
      target: '.step5',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.BEGINYEAR',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.BEGINYEAR',
      }),
    },
    {
      target: '.step6',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.BASEYEAR',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.BASEYEAR',
      }),
    },
    {
      target: '.step7',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.FREQUENCY',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.FREQUENCY',
      }),
    },
    {
      target: '.step8',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.ENDYEAR',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.ENDYEAR',
      }),
    },
    {
      target: '.step9',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.CURRENCY',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.CURRENCY',
      }),
    },
    {
      target: '.step10',
      title: intl.formatMessage({
        id: 'FIRMBACKGROUND.DECIMAL',
      }),
      content: intl.formatMessage({
        id: 'tooltip.FIRMBACKGROUND.DECIMAL',
      }),
    },
    {
      target: '.analysis_button',
      content: intl.formatMessage({ id: 'FIRMBACKGROUND.PROCED_BUTTON' }),
    },
  ]

  const TABS_FINANCIAL_STATEMENTS = [
    {
      target: '.common-row1-financial',
      title: intl.formatMessage({
        id: 'FINANCIAL_STATEMENT.BALANCE_SHEET_TABLE.TITLE',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FINANCIAL_STATEMENTS_BALANCE',
      }),
      placement: 'top',
      styles: {
        spotlight: {
          paddingBottom: '440px',
        },
      },
      disableBeacon: true,
    },
    {
      target: '.common-row2-financial',
      title: intl.formatMessage({
        id: 'FINANCIAL_STATEMENT.INCOME_STATEMETNS_TABLE.TITLE',
      }),
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FINANCIAL_STATEMENTS_INCOME',
      }),
      placement: 'top',

      styles: {
        spotlight: {
          paddingBottom: '330px',
          // marginTop:"200px"
        },
      },
    },
    {
      target: '.common-row3-financial',
      title: intl.formatMessage({
        id: 'FINANCIAL_STATEMENT.PREDICTION',
      }),
      content: 'Results',
      placement: 'top',

      styles: {
        spotlight: {
          paddingBottom: '760px',
          marginTop: '380px',
          //d scrollDuration:window.scrollTo(0,700)
        },

        tooltip: {
          marginTop: '20px',
        },
      },
    },
    {
      target: '.baseline_content',
      content: intl.formatMessage({
        id: 'PROGRESS_TABLE.FIRM_RATING',
      }),
    },
    {
      target: '.excel_content',
      content: intl.formatMessage({
        id: 'PROGRESS_TABLE.EXCEL',
      }),
    },
    {
      target: '.custom_content',
      content: intl.formatMessage({
        id: 'PROGRESS_TABLE.FIRM_VALUDATION',
      }),
    },
    {
      target: '.save_btn',
      content: intl.formatMessage({
        id: 'COMPONENT.ANALYSIS_BUTTONS.SAVE',
      }),
    },
    {
      target: '.next_button',
      content: intl.formatMessage({
        id: 'COMPONENT.ANALYSIS_BUTTONS.NEXT',
      }),
    },
    {
      target: '.previous_btn',
      content: intl.formatMessage({
        id: 'COMPONENT.ANALYSIS_BUTTONS.PREVIOUS',
      }),
    },
  ]
  const TABS_FX = [
    {
      target: '.active',
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.FX_POSITION',
      }),
      disableBeacon: true,
    },
  ]
  const TABS_MATURITY = [
    {
      target: '.active',
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.MATURITY_SHORT_TERM',
      }),
      disableBeacon: true,
    },
  ]
  const TABS_BUSINESS = [
    {
      target: '.active',
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.BUSINESS_PLAN',
      }),
      disableBeacon: true,
    },
  ]
  const TABS_CUSTOM = [
    {
      target: '.active',
      content: intl.formatMessage({
        id: 'tooltip_TABS_ROUTER.CUSTOM_SCENARIO',
      }),
      disableBeacon: true,
    },
    {
      target: '.common-row1-custom',
      content: 'input',
      placement: 'top',

      styles: {
        spotlight: {
          paddingBottom: '350px',
          marginTop: '50px',
        },
      },
      disableBeacon: true,
    },

    {
      target: '.save_btn_custom',
      content: intl.formatMessage({
        id: 'COMPONENT.CUSTOM_ANALYSIS_BUTTON.SAVE',
      }),
    },
    {
      target: '.remove_btn_custom',
      content: intl.formatMessage({
        id: 'CUSTOM_SCENARIO.REMOVE_BUTTON',
      }),
    },
  ]

  const startTour = () => {
    dispatch({ type: storeConstants.RESTART })
  }

  useEffect(() => {
    setTimeout(() => {
      tourState.indexTab === false && startTour()
    }, 400)
  }, [tourState.indexTab])

  const callback = (data) => {
    const { action, index, type, status } = data
    if (action === ACTIONS.NEXT) {
      if (activeTab === 1 && index === 1) {
        setTimeout(() => {
          index === 1 && window.scrollTo(0, screen.height / 2)
        }, 200),
          dispatch({ type: storeConstants.INDEX_TAB1, payload: true })
      }
      if (activeTab === 1 && index === 2) {
        dispatch({ type: storeConstants.INDEX_TAB, payload: false })
      }
      if (activeTab === 5 && index === 0) {
        dispatch({ type: storeConstants.INDEX_TAB5, payload: true })
      }
      if (activeTab === 5 && index === 1) {
        dispatch({ type: storeConstants.INDEX_TAB4, payload: true })
      }
    }

    if (action === ACTIONS.PREV) {
      if (activeTab === 1 && (index === 0 || index === 1)) {
        dispatch({ type: storeConstants.INDEX_TAB1, payload: false }),
          dispatch({ type: storeConstants.STOP })
      }

      if (activeTab === 5 && (index === 1 || index === 2)) {
        dispatch({ type: storeConstants.INDEX_TAB4, payload: false })
        dispatch({ type: storeConstants.INDEX_TAB5, payload: true })
      }
      if (activeTab === 5 && (index === 1 || index === 2)) {
        dispatch({ type: storeConstants.INDEX_TAB5, payload: false })
      }
    }

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      dispatch({ type: storeConstants.STOP })
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: storeConstants.NEXT_OR_PREV,
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      })
    }
  }

  useEffect(() => {
    if (activeTab === 0) {
      dispatch({ type: storeConstants.STDATA, payload: FIRMBACKGROUND_STEPS })
      activeTab === 0 &&
        dispatch({ type: storeConstants.ACTIVE_TAB, payload: false })
      tourState.activeTab === false && dispatch({ type: storeConstants.STOP })
      activeTab === 1 &&
        dispatch({ type: storeConstants.ACTIVE_TAB, payload: true })
      startTour()
      tourState.activeTab === false && dispatch({ type: storeConstants.STOP })
    }

    if (activeTab === 1 && isLoading === false) {
      dispatch({
        type: storeConstants.STDATA,
        payload: TABS_FINANCIAL_STATEMENTS,
      })
      activeTab === 1 &&
        dispatch({ type: storeConstants.ACTIVE_TAB1, payload: false })
      tourState.activeTab1 === false && dispatch({ type: storeConstants.STOP })
      activeTab === 2 &&
        dispatch({ type: storeConstants.ACTIVE_TAB1, payload: true })
      startTour()
      tourState.activeTab1 === false && dispatch({ type: storeConstants.STOP })
    }

    if (activeTab === 2) {
      dispatch({ type: storeConstants.STDATA, payload: TABS_FX })
      activeTab === 2 &&
        dispatch({ type: storeConstants.ACTIVE_TAB2, payload: false })
      tourState.activeTab2 === false && dispatch({ type: storeConstants.STOP })
      activeTab === 3 &&
        dispatch({ type: storeConstants.ACTIVE_TAB2, payload: true })
      startTour()
      tourState.activeTab2 === false && dispatch({ type: storeConstants.STOP })
    }

    if (activeTab === 3) {
      dispatch({ type: storeConstants.STDATA, payload: TABS_MATURITY })
      activeTab === 3 &&
        dispatch({ type: storeConstants.ACTIVE_TAB3, payload: false })
      tourState.activeTab3 === false && dispatch({ type: storeConstants.STOP })
      activeTab === 4 &&
        dispatch({ type: storeConstants.ACTIVE_TAB3, payload: true })
      startTour()
      tourState.activeTab3 === false && dispatch({ type: storeConstants.STOP })
    }

    if (activeTab === 4) {
      dispatch({ type: storeConstants.STDATA, payload: TABS_BUSINESS })
      activeTab === 4 &&
        dispatch({ type: storeConstants.ACTIVE_TAB4, payload: false })
      tourState.activeTab3 === false && dispatch({ type: storeConstants.STOP })
      activeTab === 5 &&
        dispatch({ type: storeConstants.ACTIVE_TAB4, payload: true })
      startTour()
      tourState.activeTab4 === false && dispatch({ type: storeConstants.STOP })
    }

    if (activeTab === 5) {
      dispatch({ type: storeConstants.STDATA, payload: TABS_CUSTOM })
      activeTab === 5 &&
        dispatch({ type: storeConstants.ACTIVE_TAB5, payload: false })
      tourState.activeTab4 === false && dispatch({ type: storeConstants.STOP })
      activeTab === 6 &&
        dispatch({ type: storeConstants.ACTIVE_TAB5, payload: true })
      startTour()
      tourState.activeTab5 === false && dispatch({ type: storeConstants.STOP })
    }
  }, [activeTab, isLoading === false])

  const isWeb = window.screen.width > 1000
    console.log("activeTab",activeTab)

  return (
    <>
      {isWeb && analysisListResult?.length === 0 ? (
        <JoyRide
          {...tourState}
          callback={callback}
          showSkipButton={true}
          showProgress={true}
          styles={{
            tooltipContainer: {
              textAlign: 'center',
            },
            buttonBack: {
              marginRight: 10,
            },
          }}
          locale={{
            last: 'End tour',
          }}
          disableScrollParentFix={true}
        />
      ) : (
        ''
      )}
      <div className="d-flex container">
        {analysisListResult?.length === 0 &&
          tabsNames.map((item, index) => (
            console.log("index:",index),
            <button
              onClick={activeTab !== 0 && (() => setActiveTab(index))}
              className={`tab ${activeTab === index ? 'active' : ''}`}
              key={index}
              disabled={index === 6 && !reportButtonControl()}
              style={{
                border: 'white',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              data-tip={
                index === 0
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FIRMBACKGROUND',
                    })
                  : index === 1
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FINANCIAL_STATEMENTS',
                    })
                  : index === 2
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FX_POSITION',
                    })
                  : index === 3
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.MATURITY_SHORT_TERM',
                    })
                  : index === 4
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.BUSINESS_PLAN',
                    })
                  : index === 5
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.CUSTOM_SCENARIO',
                    })
                  : intl.formatMessage({ id: 'tooltip_TABS_ROUTER.REPORT' })
              }
            >
              <div
                style={{
                  padding: '24px 5px',
                  maxHeight: '20px',
                  width: '150px',
                }}
              >
                {intl.formatMessage({ id: item })}
                <ReactTooltip />
              </div>
            </button>
          ))}
        {analysisListResult?.length !== 0 &&
          tabsNames.map((item, index) => (
            <button
              onClick={activeTab !== 0 && (() => setActiveTab(index))}
              className={`tab ${activeTab === index ? 'active' : ''}`}
              key={index}
              disabled={index === 6 && !reportButtonControl()}
              style={{
                border: 'white',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              data-tip={
                index === 0
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FIRMBACKGROUND',
                    })
                  : index === 1
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FINANCIAL_STATEMENTS',
                    })
                  : index === 2
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.FX_POSITION',
                    })
                  : index === 3
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.MATURITY_SHORT_TERM',
                    })
                  : index === 4
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.BUSINESS_PLAN',
                    })
                  : index === 5
                  ? intl.formatMessage({
                      id: 'tooltip_TABS_ROUTER.CUSTOM_SCENARIO',
                    })
                  : intl.formatMessage({ id: 'tooltip_TABS_ROUTER.REPORT' })
              }
            >
              <div
                style={{
                  padding: '24px 5px',
                  maxHeight: '20px',
                  width: '150px',
                }}
              >
                {intl.formatMessage({ id: item })}
                <ReactTooltip />
              </div>
            </button>
          ))}
      </div>
    </>
  )
}

export default TabsRouter
