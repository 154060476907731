import React, { useEffect, useState } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CountryFlag from 'components/CountryFlag'
import { logOut } from 'redux/actions/auth'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Button from '@mui/material/Button'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import styles from '.././../assets/styles/components/accordion/accordion.module.scss'
import storeConstants from 'redux/constants/actionTypes'
import { firmDelete, getFirmList } from 'redux/ApiCalls'

const Header = () => {
  const intl = useIntl()
  const auth = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { firms } = useSelector((state) => state.getFirmList)

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  useEffect(() => {
    getFirmList(auth.token)
      .then((res) => {
        dispatch({
          type: storeConstants.GET_FIRM_LIST,
          payload: res.data.results,
        })
      })
      .catch((e) => console.log(e))
  }, [])

  function changeLocation(placeToGo) {
    navigate(placeToGo, { replace: true })
    window.location.reload()
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {firms.map((firm) => {
          return (
            <ListItem key={firm} disablePadding>
              <div className={styles.link_and_delete}>
                <Link
                  className={styles.link_and_delete_link}
                  to={`/analysis/${firm.slug}`}
                >
                  <Button
                    className={styles.link_and_delete_link_button}
                    variant="contained"
                    onClick={() => {
                      dispatch({
                        type: storeConstants.TABS_ROUTER,
                        payload: true,
                      })
                    }}
                    size="small"
                  >
                    <ListItemText primary={firm.name} />
                  </Button>
                </Link>
                <div className={styles.link_and_delete_delete}>
                  <Button
                    className={styles.link_and_delete_delete_button}
                    onClick={() => {
                      firmDelete(auth.token, firm.slug).then(() => {
                        toast.success(`${firm.name} deleted`)
                        getFirmList(auth.token)
                          .then((res) => {
                            dispatch({
                              type: storeConstants.GET_FIRM_LIST,
                              payload: res.data.results,
                            })
                            changeLocation('/analysis')
                          })
                          .catch((e) => console.log(e))
                      })
                    }}
                    size="small"
                    variant="outlined"
                    color="error"
                  >
                    <i
                      style={{ fontSize: '20px' }}
                      className="fa fa-trash"
                      aria-hidden="true"
                    ></i>
                  </Button>
                </div>
              </div>
            </ListItem>
          )
        })}
      </List>

      <Link
        id={styles.accordion_main_container_content_add_link}
        to="/analysis"
        onClick={() => changeLocation('/analysis')}
      >
        <Button
          type="submit"
          fullWidth
          size="small"
          variant="contained"
          id={styles.accordion_main_container_content_add_button}
        >
          {intl.formatMessage({
            id: 'FIRMACCORDION.BUTTON_CREATE',
          })}
          <i className="fa fa-plus" aria-hidden="true"></i>
        </Button>
      </Link>
    </Box>
  )

  return (
    <header className="header">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        style={{ fontWeight: 'bold' }}
      >
        <Container>
          <Navbar.Brand href="/homepage" className="header-menu-logo">
            <>
              <span className=" nav-logo-1" id="nav-logo">
                Financial
              </span>
              <span className=" nav-logo-2" id="nav-logo">
                Prediction
              </span>
              <span className="header-menu-logo-lion" id="nav-logo">
                <span className="header-menu-logo-lion nav-logo-3">AI</span>
              </span>
            </>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse>
            <Nav className="mx-auto">
              {auth.token ? (
                ['left'].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Nav.Link onClick={toggleDrawer(anchor, true)}>
                      {intl.formatMessage({
                        id: 'NAV.BUTTON.ANALYSIS',
                      })}
                    </Nav.Link>
                    <SwipeableDrawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      onOpen={toggleDrawer(anchor, true)}
                    >
                      {list(anchor)}
                    </SwipeableDrawer>
                  </React.Fragment>
                ))
              ) : (
                <Nav.Link href="/analysis">
                  {intl.formatMessage({
                    id: 'NAV.BUTTON.ANALYSIS',
                  })}
                </Nav.Link>
              )}

              {auth.token ? (
                <Nav.Link href="/loan">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
                </Nav.Link>
              ) : (
                <Nav.Link href="/get-loan">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.LOANS_REQUESTS' })}
                </Nav.Link>
              )}

              {auth.token ? (
                <Nav.Link href="/equity">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
                </Nav.Link>
              ) : (
                <Nav.Link href="/get-equity">
                  {intl.formatMessage({ id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS' })}
                </Nav.Link>
              )}

              {auth.token ? (
                <>
                  <Nav.Link href="/myfirm">
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.MY_FIRM',
                    })}
                  </Nav.Link>
                  <Nav.Link href="/myfirm-update">
                    {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                  </Nav.Link>
                  <Nav.Link
                    onClick={() =>
                      dispatch(
                        logOut(),
                        navigate('/homepage'),
                        toast.info('successfully logged out')
                      )
                    }
                  >
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGOUT',
                    })}
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link href="/login">
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGIN',
                    })}
                  </Nav.Link>
                  <Nav.Link href="/register">
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.SIGN_UP',
                    })}
                  </Nav.Link>
                </>
              )}
              <CountryFlag />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
