import { Outlet } from 'react-router-dom'
import { Header } from 'components/layout'
import AppAppBar from 'components/AppAppBar'

const AppLayout = () => {
  return (
    <>
      <Header />

      <main>
        <Outlet />
      </main>
    </>
  )
}

export default AppLayout
