import * as React from 'react'
import PropTypes from 'prop-types'

import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'
import MenuIcon from '@mui/icons-material/Menu'
import ToggleColorMode from './ToggleColorMode'
import { Link, useNavigate } from 'react-router-dom'
import { logOut } from 'redux/actions/auth'

import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import CountryFlag from './CountryFlag'
const logoStyle = {
  width: '140px',
  height: 'auto',
  cursor: 'pointer',
}

function AppAppBar({ mode, toggleColorMode }) {
  const [open, setOpen] = React.useState(false)

  const intl = useIntl()
  const auth = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen)
  }
  return (
    <div>
      <AppBar
        position="block"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            className="home-toolbar home-toolbar-box"
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
              }}
            >
              <div
                style={{ padding: '0px 10px' }}
                onClick={() => navigate('/homepage')}
              >
                FinancialPredictionAI
              </div>
              <Box sx={{ display: { xs: 'none', md: 'flex', color: 'black' } }}>
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)()
                    navigate('/analysis')
                  }}
                >
                  {intl.formatMessage({ id: 'NAV.BUTTON.ANALYSIS' })}
                </MenuItem>
                {auth.token ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/loan')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.LOANS_REQUESTS',
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/equity')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS',
                      })}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/get-loan')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.LOANS_REQUESTS',
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/get-equity')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS',
                      })}
                    </MenuItem>
                  </>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: 'block', md: 'none' }, // Show only on small screens
              }}
            >
              <Button onClick={toggleDrawer(true)}>
                <MenuIcon />
              </Button>
              <Drawer
                anchor="bottom" // Menüyü aşağı doğru genişlet
                open={open}
                onClose={toggleDrawer(false)}
                transitionDuration={300} // Geçiş süresi ekleyin
                sx={{
                  '& .MuiDrawer-paper': {
                    width: '100%',
                    height: 'auto',
                    position: 'absolute',
                    bottom: 0, // Ekranın altına yerleştir
                    backgroundImage: ' linear-gradient(180deg, #CEE5FD, #FFF)',
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    toggleDrawer(false)()
                    navigate('/analysis')
                  }}
                >
                  {intl.formatMessage({ id: 'NAV.BUTTON.ANALYSIS' })}
                </MenuItem>
                {auth.token ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/loan')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.LOANS_REQUESTS',
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/equity')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS',
                      })}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/get-loan')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.LOANS_REQUESTS',
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/get-equity')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'SIDEBAR_ROUTES.EQUITY_REQUESTS',
                      })}
                    </MenuItem>
                  </>
                )}
                {auth.token ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/myfirm')
                      }}
                    >
                      {intl.formatMessage({
                        id: 'NAV.BUTTON.MY_FIRM',
                      })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/myfirm-update')
                      }}
                    >
                      {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        dispatch(logOut())
                        navigate('/homepage')
                        toast.info('successfully logged out')
                      }}
                    >
                      {intl.formatMessage({ id: 'NAV.BUTTON.LOGOUT' })}
                    </MenuItem>
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/login')
                      }}
                    >
                      {intl.formatMessage({ id: 'NAV.BUTTON.LOGIN' })}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        toggleDrawer(false)()
                        navigate('/register')
                      }}
                    >
                      {intl.formatMessage({ id: 'NAV.BUTTON.SIGN_UP' })}
                    </MenuItem>
                  </>
                )}
                <CountryFlag />
              </Drawer>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <CountryFlag />
              {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
              {auth.token ? (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() => navigate('/myfirm')}
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.MY_FIRM',
                    })}
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() => navigate('/myfirm-update')}
                    target="_blank"
                  >
                    {intl.formatMessage({ id: 'SIDEBAR_ROUTES.ACCOUNT' })}
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() =>
                      dispatch(
                        logOut(),
                        navigate('/homepage'),
                        toast.info('successfully logged out')
                      )
                    }
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGOUT',
                    })}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() => navigate('/login')}
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.LOGIN',
                    })}
                  </Button>
                  <Button
                    color="primary"
                    variant="text"
                    size="small"
                    component="a"
                    onClick={() => navigate('/register')}
                    target="_blank"
                  >
                    {intl.formatMessage({
                      id: 'NAV.BUTTON.SIGN_UP',
                    })}
                  </Button>
                </>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  )
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
}

export default AppAppBar
