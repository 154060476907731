import { useEffect, useState } from 'react'
import AnalysisButtons from 'components/AnalysisButtons'
import TabsRouter from 'components/TabsRouter'
import BusinessPlan from 'pages/BusinessPlan'
import CustomScenario from 'pages/CustomScenario'
import FinancialStatements from 'pages/FinancialStatements'
import Firmbackground from 'pages/Firmbackground'
import FxPosition from 'pages/FxPosition'
import MaturityShortTerm from 'pages/MaturityShortTerm'
import Report from 'pages/Report'
import './../assets/styles/fixedTableHeader.scss'
import { getFirmList } from 'redux/ApiCalls'
import { useDispatch, useSelector } from 'react-redux'
import storeConstants from 'redux/constants/actionTypes'
import ProgressTableAndExcelExport from 'components/ProgressTableAndExcelExport'

const AnalysisPage = () => {
  const [tab, setTab] = useState(0)
  const { token } = useSelector((state) => state.auth)
  const [isLoading, setIsLoading] = useState(true)
  const [excelRead, setExcelRead] = useState(null)
  const [isLastCustomScenario, setIsLastCustomScenario] = useState(false)

  const { tabsRouter } = useSelector((state) => state.analysisDataReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (tab === 1)
      getFirmList(token)
        .then((res) => {
          dispatch({
            type: storeConstants.GET_FIRM_LIST,
            payload: res.data.results,
          })
        })
        .catch((e) => console.log(e))
  }, [tab])

  useEffect(() => {
    if (tabsRouter) {
      setTab(0)
    }
  }, [tabsRouter])
  

  return (
    <>
      <div className="container common_analysis_page">
        <div className="common_sidebar_and_table">
          <div className="common_tab_and_table">
            <TabsRouter
              changePage={() => setTab(1)}
              activeTab={tab}
              setActiveTab={setTab}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
            <div>
              <div>
                {tab !== 0 && (
                  <div className={`table-header__cell ${'sticky-col'}`}>
                    <ProgressTableAndExcelExport
                      excelRead={excelRead}
                      setExcelRead={setExcelRead}
                    />
                  </div>
                )}
                {tab === 0 ? (
                  <>   <Firmbackground changePage={() => setTab(1)} /></>
               
                ) : tab === 1 ? (
                  <FinancialStatements
                    excelRead={excelRead}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                ) : tab === 2 ? (
                  <FxPosition />
                ) : tab === 3 ? (
                  <MaturityShortTerm />
                ) : tab === 4 ? (
                  <BusinessPlan />
                ) : tab === 5 ? (
                  <CustomScenario
                    isLastCustomScenario={isLastCustomScenario}
                    setIsLastCustomScenario={setIsLastCustomScenario}
                  />
                ) : tab === 6 ? (
                  <Report />
                ) : null}
              </div>
              {tab !== 0 && (
                <AnalysisButtons activeTab={tab} setActiveTab={setTab} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AnalysisPage
