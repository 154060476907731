function Spinner() {
  return (
    <div className="loadingSpinnerBackground">
      <div className="loadingSpinnerContainer">
        <div className="loadingSpinner " />
        <div className="header-menu-logo inner">
          <span  style={{ color: 'white' }}>Financial</span>
          <span className="header-menu-logo-lion">
            <span style={{ fontSize: '2em' , color: 'white' }}>Prediction</span>
            <span style={{ fontSize: '2em'  }}>Ai</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default Spinner
